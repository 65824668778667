<template>
    <div class="about">
        <div class="site-content">
            <div class="content-warp">
                <div class="about-site about-info">
                    <section-title><span>❤</span>关于博客</section-title>
                    <div class="info-card">
                        <p>偶然之间看见了<a target="_blank" class="out-link" href="https://zhebk.cn/Web/Akina.html">Akina For Typecho</a>博客的主题，风格很是喜欢。</p>
                        <p>然后就开始用Vue搭建这种风格的博客，在此呢也将这套模板开放给大家。</p>
                        <p><a target="_blank" href="https://gitee.com/fengziy/Gblog" style="color: #ff6d6d;">Gitee仓库</a> | <a target="_blank" href="https://github.com/fengziye/Gblog" style="color: #ff6d6d;">Github仓库</a>记得star★哟</p>
                    </div>
                </div>
                <div class="about-me about-info">
                    <section-title id="Guestbook"><span>❤</span>给我留言</section-title>
                    <div class="info-card">
                        <div class="contactForm">
                            <div class="form-item">
                                <label for="mail">邮箱</label>
                                <input class="v" type="email" id="mail" v-model="msgData.linkMail">
                            </div>
                            <div class="form-item">
                                <label for="content">内容</label>
                                <textarea class="v" id="content" v-model="msgData.content"></textarea>
                            </div>
                            <div class="form-item">
                                <label for="mailVerify">邮箱验证</label>
                                <div class="form-item-inline">
                                    <input class="v" id="mailVerify" v-model="msgData.code"/>
                                    <button @click="getMailCode" class="code-btn" :class="{'disabled':canGetCode}" :disabled="canGetCode">{{timer?`${during}s后重发`:'获取验证码'}}</button>
                                </div>
                            </div>
                            <div class="form-item">
                                <label></label>
                                <button @click="handleSubmit" :class="{'disabled':disabled}" :disabled="disabled">提交</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import sectionTitle from '@/components/section-title'
    import { checkMail } from '@/utils'
    import {contactMe, sendMailCode} from '../api'
    export default {
        name: "About",
        data() {
            return {
                clicked: false,
                msgData: {
                    linkMail: '',
                    content: '',
                    code: ''
                },
                timer: undefined,
                during: 90
            }
        },
        components: {
            sectionTitle
        },
        computed: {
            disabled() {
               return  this.clicked || !this.msgData.content || !this.msgData.code || !checkMail(this.msgData.linkMail)
            },
            canGetCode() {
                return this.timer || !checkMail(this.msgData.linkMail)
            }
        },
        methods: {
            resetForm(){
                this.msgData = {
                    linkMail: '',
                    content: '',
                    code: ''
                }
                this.during = 90
                this.timer = undefined
            },
            handleSubmit(){
                this.clicked = true
                const data = Object.assign({},this.msgData)
                contactMe(data).then(() => {
                    this.$message({
                        message: '留言成功啦~',
                        type: 'success',
                        offset: 50
                    })
                    this.resetForm()
                }).catch(err => {
                    this.$message({
                        message: err,
                        type: 'error',
                        offset: 50
                    })
                }).finally(() => {
                    this.clicked = false
                })
            },
            getMailCode(){
                if (this.timer) {
                    return
                }
                sendMailCode(this.msgData.linkMail).then(() => {
                    this.timer = setInterval(() => {
                        if (this.during > 0) {
                            this.during--
                        }else {
                            clearInterval(this.timer)
                            this.timer = undefined
                            this.during = 90
                        }
                    },1000)
                    this.$message({
                        message: `前往${this.msgData.linkMail}查看验证码吧！`,
                        type: 'info',
                        offset: 50
                    })
                }).catch((err) => {
                    this.$message({
                        message: err,
                        type: 'error',
                        offset: 50
                    })
                })
            }
        },
        mounted() {
        },
        beforeDestroy() {
            this.resetForm()
        }
    }
</script>
<style scoped lang="less">
    .about {
        padding-top: 40px;
    }

    .content-warp {
        margin-top: 80px;

        .about-info {
            margin: 30px 0;

            span {
                color: red;
                margin-right: 10px;
            }

            .info-card {
                min-height: 100px;
                padding: 20px;
                border-radius: 3px;
                margin: 30px 0 50px 0;
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                p{
                    line-height: 1.7rem;
                }
            }
        }
        .contactForm{
            width: 100%;
            padding: 20px;
            .form-item{
                align-items: center;
                display: flex;
                &:not(:last-child){
                    margin-bottom: 20px;
                }
                label{
                  width: 100px;
                }
                .v{
                    min-height: 40px;
                    line-height: 20px;
                    border-radius: 3px;
                    padding: 2px 10px;
                    outline:none;
                    border: 1px solid #8fd0cc;
                    width: 100%;
                    resize: vertical;
                }
                .form-item-inline{
                    width: 100%;
                }
                #content{
                    min-height: 70px;
                    max-height: 100px;
                }
                #mailVerify{
                    width: 50%;
                }
                button{
                    width: 100px;
                    height: 40px;
                    border-radius: 3px;
                    outline:0;
                    border-style: none;
                    cursor: pointer;
                    background-color: #409eff;
                    color: white;
                    &.code-btn{
                        width: 80px;
                        margin-left: 8%;
                    }
                    &:hover{
                        opacity: 0.8;
                    }
                    &.disabled{
                        opacity: 0.3;
                    }
                }
            }
        }
    }

    /*******/
    @media (max-width: 800px) {
        .content-warp {
            margin-top: 0;
        }
        .form-item-inline{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
</style>
